.footer1 {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 40px;
  padding: 0px 12.5%;
  padding-right: 11.2%;
  padding-top: 80px !important;
}
/* -------------- left_footer */
.left_footer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
}
.left_footer img {
  width: 161px;
  height: 36px;
  flex-shrink: 0;
}
.left_footer p {
  color: #fff;
  font-family: "Nunito", sans-serif;
  font-size: 23.367px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
/* ---------------right_footer */
.right_footer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 20px;
}
.right_footer ul {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 0%;
}
.right_footer ul li {
  
  text-decoration: none;
  color: #9CA3AF;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 0;
  margin-left: 32px;
  cursor: pointer;
  list-style: none;
}

.right_footer ul li a {
  color: #9CA3AF;
  text-decoration: none;
}
.right_footer ul li a:hover {
  color: #FFFFFF;
}
/* .right_footer button {
  display: inline-flex;
  padding: 8px 12px;
  justify-content: center;
  align-items: center;
  outline: none;
  background-color: transparent;
  cursor: pointer;
  border: 2px solid transparent; 
  border-image: linear-gradient(to right, #D2DBF0, #AC83E4, #EAA7D1, #FECE84, #E28CA3) 1; 
  border-radius: 48px !important; 
  color: white;
} */

.right_footer button {
  display: inline-flex;
  padding: 8px 12px;
  justify-content: center;
  align-items: center;
  outline: none;
  background-color: #0E0C16 !important;
  border: 2px solid transparent;
  border-radius: 48px;
  color: white;
  cursor: pointer;
  position: relative;
  z-index: 5;
  
}

.right_footer button::before {
  content: '';
  position: absolute;
  top: -2px;
  right: -2px;
  bottom: -2px;
  left: -2px;
  background: linear-gradient(to right, #D2DBF0, #AC83E4, #EAA7D1, #FECE84, #E28CA3);
  border-radius: 48px;
  z-index: -2;
}
.right_footer button::after {
  content: '';
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  background-color: #0E0C16;
  border-radius: 48px;
  z-index: -1;
}


.btn {
  display: none !important;
}
.cross {
  position: absolute;
  top: 15px;
  left: 15px;
  font-size: 23px;
  cursor: pointer;
  display: none;
}
.burger {
  font-size: 23px;
  cursor: pointer;
  display: none;
}

/* =================  */
@media (max-width: 850px) {
  /* .right_footer {
    position: fixed;
    left: 0;
    top: 0;
    width: 350px;
    height: 100vh;
    background-color: #13101c;
    z-index: 20;
    flex-direction: column;
    justify-content: center;
    justify-content: center;
    align-items: center;
    transform: translateX(-100%);
    transition: transform 0.2s linear;
  }
  .show_sidebar {
    transform: translateX(0);
    transition: transform 0.2s linear;
  } */
  .btn {
    display: block !important;
    margin-top: 20px;
  }
  .cross {
    display: block;
  }
  .burger {
    display: block;
  }

  .footer1{
    display: flex;
    flex-direction: column;
  }

  .right_footer ul{
    display: flex;
    flex-direction: column;
    padding-top: 50px;
  }

  .right_footer ul li {
    padding-top: 20px;
  }
}
