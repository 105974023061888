.home {
  /* background: #0e0c16;
  color: white; */
  padding: 0px 12.5%;
}

@media (max-width: 1050px) {
  .home {
    padding: 30px 50px;
  }
}

@media (max-width: 500px) {
  .home {
    padding: 30px 10px;
  }
}
