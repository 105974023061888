.bottom_footer {
  padding: 45px 13.1%;
  padding-right: 11.5%;
  border-top: 2px solid #201929;
  margin-top: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  
}
.bottom_footer p {
  color: var(--gray-400, #9ca3af);
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 24px */
  margin-bottom: 15px;
  order: 1;
}
.footer_icons {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
  order: 2;
}

.twitter-logo-foot {
  font-size: 22px !important;
  margin-right: 3px;
}

.footer_icons svg {
  font-size: 28px;
  cursor: pointer;
  color: #9CA3AF;
}

.footer svg:hover {
  color:#FFFFFF;
}
.footer {
  width: auto !important;
}

/* -------------  */
@media (max-width: 500px) {
  .bottom_footer {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    border-top: none;
  }

  .bottom_footer p {
    padding-top: 40px;
    text-align: center;
    order: 2;
  }
}
