.SearchAndCategory {
  margin: 50px 0px;
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.SearchAndCategory h1 {
  color: #fff;
  text-align: center;
  font-family: "Inter", sans-serif;
  font-size: 48px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 22px;
}

.SearchAndCategory p {
  width: 672px;
  margin-top:20px;
  margin-bottom:3%;
  text-align: center;
  font-family: "Inter", sans-serif;
  font-size: 18px;
  font-weight: 300 !important;
}

/* ----------  */
.search_input_div {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 15px;
  width: 540px;
  height: 64px;
  flex-shrink: 0;
  border-radius: 41px;
  background: #171520;
  padding: 1px 20px;
  margin-bottom: 20px;
}
.search_input_div svg {
  width: 24px;
  height: 24px;
  flex-shrink: 0;
  cursor: pointer;
}
.search_input_div input {
  width: 100%;
  background-color: transparent;
  border: none;
  outline: none;
  padding: 5px;
  color: #fff;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

/* ------------ category */
.category {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 7px;
  overflow-x: scroll !important;
  margin-top: 20px;
  width: 100%;
}
.category li {
  list-style: none;
  cursor: pointer;
  display: flex;
  width: 170px;
  padding: 8px 12px;
  justify-content: center;
  align-items: center;
  border-radius: 48px;
  border: 1px solid #fff;
  flex-shrink: 0;
  font-family: "Inter", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400 !important;
}
.category::-webkit-scrollbar {
  width: 0;
  background: transparent;
}

/* ==================  */
@media (max-width: 768px) {
  .SearchAndCategory h1 {
    font-size: 35px;
  }
  .search_input_div {
    width: 450px;
  }
  .SearchAndCategory p {
    width: auto;
    margin-bottom: 3%;
    margin-top: 20px;
  }
}



@media (max-width: 500px) {
  .SearchAndCategory h1 {
    font-size: 30px;
  }
  .search_input_div {
    width: 100%;
  }
}
