.card_parent {
  margin: 50px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.main_card {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 25px;
  position: relative;
  max-width: 1200px;
}
.main_liner_color {
  width: 100%;
  z-index: 3;
  height: 130px;
  position: absolute;
  top: 50%; /* Center vertically */
  left: 50%; /* Center horizontally */
  transform: translate(-50%, -50%); /* Center using translate */
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(
    111deg,
    #d2dbf0 7.02%,
    #ac83e4 19.07%,
    #eaa7d1 38.12%,
    #fece84 57.96%,
    #e28ca3 81.68%
  );
  filter: blur(156.5px);
}
/* -------- card_box */
.card_box {
  width: 255px;
  height: 497px;
  flex-shrink: 0;
  border-radius: 12px;
  background: #181623;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  padding: 20px 7px;
  position: relative;
  border-radius: 12px;
  background: #181623;
  overflow: hidden;
  z-index: 7;
}

/* .card_box:hover {
  border: 1px solid grey;
} */
/* ------------- liner_color */
.liner_color {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 150px;
  background: linear-gradient(
    181deg,
    rgba(114, 102, 149, 0.6) 0.45%,
    rgba(136, 132, 163, 0) 99.55%
  );
  z-index: 2;
}

.card_box img {
  width: 150px;
  height: 150px;
  flex-shrink: 0;
  border-radius: 27px;
  margin-bottom: 15px;
  cursor: pointer;
  margin-top: 24px;
  z-index: 5;
}
.card_title {
  color: #fff;
  text-align: center;
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 36px */
}
.card_category {
  color: #c6c6c6;
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 24px */
}
.tik_insta {
  color: #c6c6c6;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 24px */
  margin-top: 24px;
}
.follower {
  color: #c6c6c6;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 24px */
}
.icons {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  margin: 20px 0px;
}
.icons svg {
  cursor: pointer;
  font-size: 42px;
  padding: 10px;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.22);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
}
.icons svg:hover {
  cursor: pointer;
  font-size: 42px;
  padding: 10px;
  border-radius: 10px;
  /* background: white; */
  color: white;
  background: #59556D;
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
}
.icons a {
  color: white;
}
.card_box button {
  width: 211px;
  height: 52px;
  flex-shrink: 0;
  border-radius: 12px;
  background: #fff;
  color: #000;
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 24px */
  cursor: pointer;
}
.card_box button:hover {
  border: 1px solid White;
  background: linear-gradient(90.72deg, #D2DBF0 1.29%, #AC83E4 58.36%, #EAA7D1 100%);
  color: white;
}

/* -----------  */
.LoadMore {
  text-align: center;
  display: flex;
  width: 135px;
  padding: 8px 12px;
  justify-content: center;
  align-items: center;
  border-radius: 48px;
  border: 1px solid #fff;
  color: #fff;
  /* text-xs/font-medium */
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 18px */
  margin-top: 40px;
  background-color: transparent;
  cursor: pointer;
}
.LoadMore:hover {
  border: 1px solid white;
  background: linear-gradient(88.04deg, #D2DBF0 1.92%, #AC83E4 92.22%);
}

/* -----------  */
@media (max-width: 500px) {
  .card_box {
    width: 280px;
  }
}
