.JoinWrapper{
  position: relative;
  /* max-width: 100px; */
  align-items: center;
  align-self: center;
  max-width: 1200px;
  margin: auto;
}

.upperJoin {
  min-height: 450px;
  width: 80%;
  margin-left: 10%;
  position: absolute;
  background: linear-gradient(
    111deg,
    #d2dbf0 7.02%,
    #ac83e4 19.07%,
    #eaa7d1 38.12%,
    #fece84 57.96%,
    #e28ca3 81.68%
  );
    /* overflow: hidden; */
    filter: blur(180px) brightness(40%);
    z-index: -1;
}

.join {
  margin: 60px 0px;
  border-radius: 34px;
  background: #13101c !important;
  /* padding: 40px 82px; */
  padding-bottom: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 24px;
  min-height: 414px;
  /* max-width: 1200px; */
}
.join h2 {
  color: #fff;
  text-align: center;
  font-family: Inter;
  font-size: 36px;
  font-style: normal;
  font-weight: 600;
  line-height: 140.023%; /* 50.408px */
}
.join p {
  color: #ffffff;
  text-align: center;
  font-family: 'Inter', sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%; /* 24px */
  max-width: 692px;
  padding-bottom: 10px;
}
.join_email {
  width: 500px;
  height: 68px;
  flex-shrink: 0;
  border-radius: 49px;
  background: #211e2d;
  display: grid;
  grid-template-columns: 1fr 0.3fr;
  padding: 10px 13px;
  overflow: hidden;
}
.join_email input {
  outline: none;
  border: none;
  color: #fff;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  /* height: 68px; */
  line-height: 160%; /* 24px */
  background: transparent;
  margin-bottom: 5px;
}
.join_email input::placeholder {
  padding-left: 3%;
  font-weight: 600;
  color: #afb4ba;
}
.join_email button {
  display: flex;
  width: 107px;
  height: 50px;
  padding: 8px 0px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  cursor: pointer;
  border-radius: 48px;
  background: #e8999d;
  margin-bottom: 5px;
  color: white;
  border: 1px solid black;
  font-size: 16px;
  font-family: 'Inter',sans-serif;
  font-weight: 500;
}
.join_email button:hover {
  background-color: #BA5E62;
}

@media (max-width: 768px) {
  .join{
    padding: 0% 10%;
  }
  .join h2 {
    font-size: 30px;
  }
  .join p {
    text-align: justify;
    font-size: 15px;
    /* padding: 0% 10%; */
  }
  .join_email {
    width: 100%;
    margin: 0% 10%;
    height: 50px;
    align-items: center;
  }
  .join_email button{
    height: 38px;
    width: 79px;
    margin-bottom: 0px;
    /* offset-position: 0px -2px; */

    /* margin-bottom: 20px; */
    
  }

  .join_email input::placeholder {
    margin-top: auto;
    margin-bottom: auto;
    padding: 14px;
  }
  .join_email input {
    height: 40; 
    line-height: 100%;
  }
  /* .join {
    width: 358px;

  } */
  .join_email{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

@media (max-width: 450px) {
  .join_email input {
    width: 60%;
  }
  .join_email{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
