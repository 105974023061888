.header {
  width: 100%;
  padding: 44px 0px ;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
/* -------------- left_header */
.left_header {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
}
.left_header img {
  width: 160px;
  height: 35px;
  flex-shrink: 0;
}
.left_header p {
  color: #fff;
  font-family: "Nunito", sans-serif;
  font-size: 23.367px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
/* ---------------right_header */
.right_header {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 20px;
}
.right_header ul {
  display: flex;
  justify-content: center;
  align-items: center;
}
.right_header ul li {
  color: #fff;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding: 3px 19px;
  /* padding-right: 4px !important; */
  /* margin: 0px 16px; */
  cursor: pointer;
  list-style: none;
}

.right_header ul li a {
  text-decoration: none;
  color: #fff;
}

.right_header ul li:hover {
  background: linear-gradient(88.04deg, #D2DBF0 1.92%, #AC83E4 92.22%);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
}

.active-link {
  background: linear-gradient(88.04deg, #D2DBF0 1.92%, #AC83E4 92.22%);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
}
/* .right_header button {
  display: inline-flex;
  padding: 8px 12px;
  justify-content: center;
  align-items: center;
  outline: none;
  background-color: transparent;
  cursor: pointer;
  border: 2px solid transparent; 
  border-image: linear-gradient(to right, #D2DBF0, #AC83E4, #EAA7D1, #FECE84, #E28CA3) 1; 
  border-radius: 48px !important; 
  color: white;
} */

.right_header button {
  display: inline-flex;
  padding: 9px 12px 10px 12px;
  justify-content: center;

  align-items: center;
  outline: none;
  background-color: #0E0C16 !important;
  border: 1px solid transparent;
  border-radius: 48px;
  color: white;
  cursor: pointer;
  position: relative;
  z-index: 5;
  font-size: 12px;
  font-weight: 500;
  font-family: 'Inter', sans-serif;
  margin-left: 1px;

}
.right_header button:hover {
  color: #0E0C16;
}
.right_header button:hover::after {
  background: linear-gradient(88.04deg, #D2DBF0 1.92%, #AC83E4 92.22%);

}

.right_header button::before {
  content: '';
  position: absolute;
  top: -1px;
  right: -1px;
  bottom: -1px;
  left: -1px;
  background: linear-gradient(to right, #d2dbf0ce, #AC83E4, #EAA7D1, #FECE84, #E28CA3);
  border-radius: 48px;
  z-index: -2;
}
.right_header button::after {
  content: '';
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  background: #0E0C16;
  border-radius: 48px;
  z-index: -1;
}


.btn {
  display: none !important;
}
.cross {
  position: absolute;
  top: 15px;
  left: 15px;
  font-size: 23px;
  cursor: pointer;
  display: none;
}
.burger {
  font-size: 23px;
  cursor: pointer;
  display: none;
}

/* =================  */
@media (max-width: 850px) {
  .header {
    margin-top: 0px;
    padding: 0px 0px;
  }
  .right_header {
    position: fixed;
    left: 0;
    top: 0;
    width: 350px;
    height: 100vh;
    background-color: #13101c;
    z-index: 20;
    flex-direction: column;
    justify-content: center;
    align-items: top;
    transform: translateX(-100%);
    transition: transform 0.2s linear;
  }
  .show_sidebar {
    transform: translateX(0);
    transition: transform 0.2s linear;
  }
  .right_header ul {
    flex-direction: column;
    gap: 10px;
  }
  /* .right_header button {
    display: none !important;
  } */
  .btn {
    display: block !important;
    margin-top: 20px;
  }
  .cross {
    display: block;
  }
  .burger {
    display: block;
  }
}
